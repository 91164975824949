.back{
    z-index: 99999;
}
.contain_date {
    z-index: 2;
}
.routarSquer {
    position: absolute;
    bottom: -50px;
    left: calc(60%);
    width: 400px;
    height: 450px;
    z-index: 0;
}
.fromtospace{
    width: 100%;

}
.fromtospace > * {
    margin: 0 8px;
} 
.herizontalSpace {
    margin-top: 10px;
}
.herizontalSpace > * {
    margin: 0 5px;
}
.header_container {
    width: 100%;
    height: 250px;
    overflow-y: visible;
    overflow-x: hidden;
}







@media screen and (max-width : 1900px ) {
    .routarSquer {
        position: absolute;
        bottom: -50px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}
@media screen and (max-width : 1024px ) {
    .routarSquer {
        position: absolute;
        bottom: -30px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}
@media screen and (max-width : 912px ) {
    .routarSquer {
        position: absolute;
        bottom: -200px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}
@media screen and (max-width : 820px ) {
    .routarSquer {
        position: absolute;
        bottom: -150px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}
@media screen and (max-width : 768px ) {
    .routarSquer {
        position: absolute;
        bottom: -130px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}

@media screen and (max-width : 540px ) {
    .routarSquer {
        position: absolute;
        bottom: -70px;
        left: calc(45%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}

@media screen and (max-width : 414px ) {
    .routarSquer {
        position: absolute;
        bottom: -70px;
        left: calc(40%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}

@media screen and (max-width : 390px ) {
    .routarSquer {
        position: absolute;
        bottom: -70px;
        left: calc(40%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}

@media screen and (max-width : 375px ) {
    .routarSquer {
        position: absolute;
        bottom: -50px;
        left: calc(40%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
}