.Edit{
    border-radius: 9px;
background: var(--primary-color, #0074C3);

/* Drop Shadow */
box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.25);
color: #FFF;
padding: 10px 32px;
margin-top: 10px;
cursor: pointer;
}
.EditBtn{
    border-radius: 9px;
background: var(--primary-color, #0074C3);

/* Drop Shadow */
box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.25);
color: #FFF;
padding: 10px 32px;

cursor: pointer;
}