
.HideOver {
    width: 100%;
    height: 200px;
    overflow-x: hidden;
    overflow-y: visible;
}
.back{
    z-index: 99999;
}
.contain_date {
    z-index: 2;
}
.routarSquer {
    position: absolute;
    bottom: -50px;
    left: calc(50%);
    width: 200px;
    height: 450px;
    z-index: 0;
}
.fromtospace{
    width: 90%;

}
.fromtospace > * {
    margin: 0 8px;
} 
.herizontalSpace {
    margin-top: 10px;
}
.herizontalSpace > * {
    margin: 0 5px;
}
.Min_filter{
    display: none;
}
@media screen and (max-width : 880px ){
    .routarSquer {
        position: absolute;
        bottom: -50px;
        left: calc(50%);
        width: 200px;
        height: 450px;
        z-index: 0;
    }
    .HideOver {
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
    }
    .Min_filter{
        display: block;
    }
}

@media screen and (max-width : 500px ){
    .routarSquer {
        position: absolute;
        bottom: -50px;
        left: calc(35%);
        width: 200px;
        height: 300px;
        z-index: 0;
    }
    .HideOver {
        width: 100%;
        overflow-x: hidden;
        overflow-y: visible;
    }
    .Min_filter{
        display: block;
    }
}

@media screen and (max-width : 280px ){
    .routarSquer {
        position: absolute;
        bottom: -50px;
        left: calc(50%);
        width: 200px;
        height: 450px;
        z-index: 0;
    }
   .from_name {
    margin: 0;
    font-size: 16px;
    
   }
   .to_name{
    margin: 0;
    font-size: 16px;
   }
   .HideOver {
    width: 100%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: visible;
}
}

/* two round  */

@media  screen and (max-width: 912px) {
.BusMinScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    border : none

}
.centerspace{
    display: none;
}
.contentMin{
    width: 100%;
    margin-bottom: 16px;
}
.ButtonMin{
    display: flex;
    justify-content: center;
    align-items: center;
}
.BusHome_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Fillter_component {
    display: none;
}
.Min_filter{
    display: block;
}
}

@media  screen and (max-width: 500px) {
.BusMinScreen{
    display: flex;
    flex-direction: column;
    align-items: center;
    border : none
}
.centerspace{
    display: none;
}
.contentMin{
    width: 100%;
    margin-bottom: 16px;
    border: 10px solid #B9C4D5;
    margin-top: 10px;
}
.ButtonMin{
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_pay_Min {
    border: 10px solid #B9C4D5;
}
.BusHome_container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Fillter_component {
    display: none;
}
.Min_filter{
    display: block;
}
}



@media  screen and (max-width: 280px) {
    .BusMinScreen{
        display: flex;
        flex-direction: column;
        align-items: center;
        border : none
    }
    .contentMin{
        width: 100%;
        margin-bottom: 16px;
        border: 10px solid #B9C4D5;
        margin-top: 10px;
    }
    .ButtonMin{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container_pay_Min {
        border: 10px solid #B9C4D5;
    }
    .centerspace{
        display: none;
    }
    .BusHome_container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .Fillter_component {
        display: none;
    }
}



/* itemes filter container  */

.filter_items {
    display: none;
    flex-direction: column;
    align-items: center;

}

.min_filter_items {
    display: none;
}

.Min_filter {
    overflow-x: hidden;
}
/* start scroll width  */
.scroll {
    width: 100%;
    height: auto;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.scroll::-webkit-scrollbar {
    display: none;
}

.f4_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 100px ;
   height : 40px ;
  
   
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f4_container {
    position: absolute;
    top: 295px;
    left: calc(0%);
    display: block;
    width: 100%;
}
.f4_dis{
    display: none;
}


.f1_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 100px ;
   height : 40px ;
   margin-left: 6px;
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f1_container {
    position: absolute;
    top: 295px;
    left: 0;
    display: block;
}
.f1_dis{
    display: none;
}


.f2_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 100px ;
   height : 40px ;
   margin-left : 6px ;
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f2_container {
    position: absolute;
    top:295px;
    left: calc(0%);
    display: block;
    width: 100%;
}
.f2_dis{
    display: none;
}


.f3_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 100px ;
   height : 40px ;
   margin-left : 6px ;
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f3_container {
    position: absolute;
    top: 295px;
    left: calc(0%);
    display: block;
    width: 100%;
}
.f3_dis{
    display: none;
}




.f5_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 150px ;
   height : 40px ;
   margin-left : 6px ;
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f5_container {
    position: absolute;
    top: 295px;
    left: calc(0%);
    display: block;
    width: 100%;
}
.f5_dis{
    display: none;
}
.f6_icon {
    display: flex;
    justify-content: center;
    align-items: center;
   width : 150px ;
   height : 40px ;
   margin-left : 6px ;
    border: 1px solid #B9C4D5;
    border-radius: 10px;
    cursor: pointer;
    background-color: white;
}
.f6_container {
    position: absolute;
    top: 295px;
    left: calc(0%);
    display: block;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.f6_dis{
    display: none;
}
/* end scroll width  */



            
              

@media  screen and (min-width: 820px) {
    .filter_items {
        display: flex;
    }
    .min_filter_items {
        display: none;
    }
   
}

@media  screen and (max-width: 820px) {
    .filter_items {
        display: none;
    }
    .min_filter_items {
        display: block;
    }
   
}

@media only screen and (max-width: 913px) and  (max-height: 1368px) {  
    .filter_items {
        display: none;
    }
    .min_filter_items {
        display: block;
    }
    .scroll {
        width: 100%;
        height: auto;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
   

}


@media only  screen and (min-width : 360px) {
  
    .f1_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }

    .f2_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }

    .f3_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }

    .f4_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }

    .f5_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }
    .f6_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 240px;
        left: 0;
        display: block;
    }
   
    
}

@media only  screen and (min-width : 768px) {
  
    .f1_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f2_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f3_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f4_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f5_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
    .f6_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 520px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
   
    
}


@media only  screen and (min-width : 820px) {
  
    .f1_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f2_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f3_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f4_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f5_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
    .f6_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 550px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
   
    
}

@media only  screen and (min-width : 912px) {
  
    .f1_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f2_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f3_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f4_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }

    .f5_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
    .f6_container {
        width: 100%;
        margin: auto;
        position: absolute;
        top: 610px;
        
        left: 0;
        display: block;
        z-index : 2234;
    }
   
    
}