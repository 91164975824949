.back{
    z-index: 99999;
}
.contain_date {
    z-index: 2;
}

.fromtospace{
    width: 100%;

}
.fromtospace > * {
    margin: 0 8px;
} 
.herizontalSpace {
    margin-top: 10px;
}
.herizontalSpace > * {
    margin: 0 5px;
}















@media screen and (max-width : 1024px )  {
    .routarSquer {
        position: absolute;
        bottom: -30px;
        left: calc(35%);
        width: 230px;
        height: 230px;
        z-index: 0;
    }

}
@media screen and (max-width : 912px )  {
    .routarSquer {
        position: absolute;
        bottom: -200px;
        left: calc(40%);
        width: 200px;
        height: 200px;
        z-index: 0;
    }

}


@media screen and (max-width : 820px )  {
    .routarSquer {
        position: absolute;
        bottom: -150px;
        left: calc(35%);
        width: 250px;
        height: 200px;
        z-index: 0;
    }

}


@media screen and (max-width : 540px ) {
    .routarSquer {
        position: absolute;
        bottom: -45px;
        left: calc(20%);
        width: 250px;
        height: 200px;
        z-index: 0;
    }

}


@media screen and (max-width : 414px ) {
    .routarSquer {
        position: absolute;
        bottom: -30px;
        left: calc(12%);
        width: 250px;
        height: 100px;
        z-index: 0;
    }
    .to_name{
        font-size: 20px;
     }
     .from_name{
         font-size: 20px;
      }
 
}

@media screen and (max-width : 375px ) {
    .routarSquer {
        position: absolute;
        bottom: -35px;
        left: calc(30%);
        width: 150px;
        height: 100px;
        z-index: 0;
    }
    .to_name{
       font-size: 16px;
    }
    .from_name{
        font-size: 16px;
     }

}
@media screen and (max-width : 360px ) {
    .routarSquer {
        position: absolute;
        bottom: -35px;
        left: calc(28%);
        width: 150px;
        height: 100px;
        z-index: 0;
    }
    .to_name{
       font-size: 16px;
    }
    .from_name{
        font-size: 16px;
     }

}

@media screen and (max-width : 280px )  {
    .routarSquer {
        position: absolute;
        bottom: -20px;
        left: calc(35%);
        width: 100px;
        height: 100px;
        z-index: 0;
    }
    .to_name {
        font-size: 14px;
    }
    .from_name {
        font-size: 14px;
    }
}