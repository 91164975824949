.cardLinks{
    background: #fff;
    width: 100%;
    height: fit-content;
    color: var(--black, #1E1E1E);
    margin-top: 2rem;
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.25);
}
.cardLinks .avatar{
    display: flex;
    flex-direction: column;
    
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
}
.cardLinks .avatar h2{
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: normal;
}
.links{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap:1.4rem;

}
.link{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

}

.link p{
    font-size: 20px;
}
.link:last-child{
    color: #C6212A;
}

.active{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #0074C3;
}
.active p {
    font-size: 20px;
}
.active  path{
    stroke: #0074C3;
   
}
@media(max-width:779px ){
    .cardLinks{
        width: 100%;
    }
}