@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Alkatra&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Spline+Sans&display=swap");
@font-face {
    font-family: 'Cairo';
    src: local('Cairo-VariableFont_slnt,wght.ttf'), url('./fonts/Cairo-VariableFont_slnt,wght.ttf') format('truetype');
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@layer base {
	[type="text"],
	[type="email"],
	[type="url"],
	[type="password"],
	[type="number"],
	[type="date"],
	[type="datetime-local"],
	[type="month"],
	[type="search"],
	[type="tel"],
	[type="time"],
	[type="week"],
	[multiple],
	textarea,
	select {
		@apply block w-full rounded-full border-neutral-200 bg-white text-neutral-900
    focus:border-indigo-500 focus:ring-indigo-500 dark:border-neutral-700 dark:bg-neutral-900 dark:text-neutral-200 sm:text-sm;
	}
}

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
}

.movie-container {
	margin: 20px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.movie-container select {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border: 0;
	padding: 5px 15px;
	margin-bottom: 40px;
	font-size: 14px;
	border-radius: 5px;
}

.movie-container .container {
	perspective: 1000px;
	margin: 40px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.bg-seat {

	background: url("./images/Car\ -\ Free\ security\ icons-1.png") no-repeat;
	height: 40px;
	width: 40px;
	margin: 5px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.movie-container .seat {
	background: url("./images/Car\ -\ Free\ security\ icons-1.png") no-repeat;
	height: 40px;
	width: 40px;
	margin: 5px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

 .min_seat {
	background: url("./images/Car\ -\ Free\ security\ icons-2.png") no-repeat;
	background-size: contain;
	height: 30px;
	width: 30px;
	margin: 5px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.movie-container .steering {
	background: url("./images/steering-wheel\ 1.png") no-repeat;
	height: 50px;
	width: 50px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.selected-seat {
	background: url("./images/Car\ -\ Free\ security\ icons-2.png") no-repeat;
	color: rgb(4, 4, 185)
}
.driver-bg {
	background: url("./images/steering-wheel_1-removebg-preview.png") no-repeat;
	/* background-color: #0081cb; */
	color: rgb(4, 4, 185);
	height: 50px;
	width: 50px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
.selected-bg {
	background: url("./images/Car - Free security icons-3.png") no-repeat;
	/* background-color: #0081cb; */
	color: rgb(242, 242, 242);
	
	
	
}
.toilet-bg {
    background: url("images/image-removebg-preview.png") no-repeat;
    height: 50px;
    width: 50px;
    object-fit: fill;
    background-size: cover;
}
.door-bg {
	height: 50px;
    width: 50px;
    object-fit: fill;
    background-size: cover;
	border-right-color: #0A162A;
	border-right-width: 2px ;
}
.space-bg {
    /* background: url("images/image-removebg-preview.png") no-repeat; */
    height: 50px;
    width: 50px;
    object-fit: fill;
    background-size: cover;
}
.movie-container .selected {
	background: url("./images/Car\ -\ Free\ security\ icons-2.png") no-repeat;
	color: rgb(4, 4, 185)
}

.movie-container .occupied {
	background: url("./images/Car\ -\ Free\ security\ icons-3.png") no-repeat;
}

.movie-container .seat:nth-of-type(2) {
	margin-right: 18px;
}

.movie-container .seat:nth-last-of-type(2) {
	margin-left: 18px;
}

.movie-container .seat:not(.occupied):hover {
	cursor: pointer;
	transform: scale(1.2);
}

.movie-container .showcase .seat:not(.occupied):hover {
	cursor: default;
	transform: scale(1);
}

.movie-container .showcase {
	display: flex;
	justify-content: space-between;
	list-style-type: none;
	padding: 5px 10px;
	border-radius: 5px;
	color: #777;
	border: 1px solid #DDDDDD;
}

.movie-container .showcase li {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 10px;
}

@media screen and (max-width : 600px) {
	.movie-container .showcase li {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 -3px;
	}
}

.movie-container .showcase li small {
	margin-left: 2px;
}

.movie-container .row {
	display: flex;
	align-items: center;
	justify-content: center;
}

.movie-container .screen {
	background: #fff;
	height: 70px;
	width: 70%;
	margin: 15px 0;
	transform: rotateX(-45deg);
	box-shadow: 0 3px 10px rgba(255, 255, 255, 0.7);
}
.triangle-container {
	justify-self: center !important;
	align-items: center !important;
}

.movie-container p.text {
	margin: 40px 0;
}

.movie-container p.text span {
	color: #0081cb;
	font-weight: 600;
	box-sizing: content-box;
}

.movie-container .credits a {
	color: #fff;
}
@media (min-width:900px) {
	.triangle {
		/* margin-left: -90%; */
		justify-content: center;
		align-items: center;
		padding-right: 30%;
		padding-left: 20%;
		border-top-left-radius:8px ;
		border-bottom-left-radius:8px ;
		}
	.triangle2 {
		/* margin-top: -9%; */
		justify-content: center;
		align-items: center;
		/* padding-right: 60px; */

		}
	.triangle3 {
		/* margin-top: -13%; */
		background-color: #DDE2EB;
		justify-content: center;
		align-items: center;
		border-top-right-radius:8px ;
		border-bottom-right-radius:8px ;
		padding-right: 40%;
		padding-left: 35%;
		}
}
@media (max-width:900px) {
	.triangle {
	/* margin-left: -90%; */
	justify-content: center;
	align-items: center;
	padding-right: 10%;
		padding-left: 10%;
		border-top-left-radius:8px ;
		border-bottom-left-radius:8px ;
	}
.triangle2 {
	/* margin-top: -9%; */
	justify-content: center;
	align-items: center;
	padding-left: 10%;
	}
.triangle3 {
	/* margin-top: -13%; */
	background-color: #DDE2EB;
	justify-content: center;
	align-items: center;
	border-top-right-radius:8px ;
	border-bottom-right-radius:8px ;
	padding-right: 40%;
	padding-left: 35%;
	}
	.restyle-container {
		/* background-color: #0081cb; */
		flex-direction: row-reverse;

	}
	.restyle-container  img {
		width: 150px;
	}
	
	.triangle h4 {
		font-size: 12px !important;
	}
	.triangle2 h4 {
		font-size: 12px !important;
	}
	.triangle3 h4 {
		font-size: 12px !important;
	}
	}
@media (max-width:600px) {
	.triangle {
		/* margin-left: -90%; */
		justify-content: center;
		align-items: center;
		padding-right: 10%;
			padding-left: 10%;
			border-top-left-radius:8px ;
		border-bottom-left-radius:8px ;
		}
	.triangle2 {
		/* margin-top: -9%; */
		justify-content: center;
		align-items: center;
		padding-left: 10%;
		}
	.triangle3 {
		/* margin-top: -13%; */
		background-color: #DDE2EB;
		justify-content: center;
		align-items: center;
		border-top-right-radius:8px ;
		border-bottom-right-radius:8px ;
		padding-right: 25%;
		padding-left: 25%;
		}
}
@media (max-width:400px) {
	.triangle {
		/* margin-left: -90%; */
		justify-content: center;
		align-items: center;
		padding-right: 10%;
			padding-left: 10%;
			border-top-left-radius:8px ;
		border-bottom-left-radius:8px ;

		}
	.triangle2 {
		/* margin-top: -9%; */
		justify-content: center;
		align-items: center;
		padding-left: 20%;
		}
	.triangle3 {
		/* margin-top: -13%; */
		background-color: #DDE2EB;
		justify-content: center;
		align-items: center;
		border-top-right-radius:8px ;
		border-bottom-right-radius:8px ;
		padding-right: 25%;
		padding-left: 15%;
		}
}
.triangle::after {
	content: "";
	position: absolute;
	left: 100%;
	z-index: 10;
	top: 0;
	width: 0;
	height: 0;
	border-top: 30px solid transparent;
	border-left: 50px solid #DDE2EB;
	border-bottom: 30px solid transparent;
}
.triangle3::before {
	content: "";
	position: absolute;
	left: -24px;
	top: 0;
	/* width: 50px; */
	/* height: 50px;	 */
	border-top: 30.39px solid #DDE2EB;
	border-left: 25px solid transparent;
	border-bottom: 30.40px solid #DDE2EB;
}
.PhoneInput {
    width: 70%;
	
  }
  .PhoneInput .PhoneInputCountry{
    margin-top: -15px;
  }
  .two{
	display:  grid;
	grid-template-columns: 1fr 2fr;
  }
  @media(max-width:779px ){
	.two{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.PhoneInput {
		width: 20rem !important;
		
	  }
  }


  /* bus  sliders  */ 


.rc-slider  {
	background: none;
    margin-top: 20px;
}

.rc-slider .rc-slider-track {
	background: none;
	
	
} 
.rc-slider .rc-slider-track .rc-slider-track-1{
	background: none;
	
	

}
.rc-slider-track-1::before{
	background: none;
}
.rc-slider-rail{
	background: none;
	height: 45px;
	background-image: url("../public/sliderBG.svg");
	background-size: 100%;
	background-repeat: no-repeat;
    transform: translateY(-30px);
}

.rc-slider-handle{
 background-color: #fff;
 border: solid 2px #9e9e9e !important;
 width: 20px;
 height: 20px;
} 
.rc-slider-handle-2{
	background-color: #2E74BD;
	border: solid 2px #2E74BD !important;
	width: 20px;
	height: 20px;
}

.rc-slider-handle .rc-slider-handle-2:focus{
 padding: 0;
 border: 0;
}
@media only  screen and (min-width : 360px) {
	.rc-slider-rail{
		background: none;
		height: 45px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-16px);
	}
}

@media only  screen and (min-width : 412px) {
	.rc-slider-rail{
		background: none;
		height: 45px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-20px);
	}
}

@media only  screen and (min-width : 540px) {
	.rc-slider-rail{
		background: none;
		height: 45px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-32px);
	}
}

@media only  screen and (min-width : 768px) {
	.rc-slider-rail{
		background: none;
		height: 50px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-40px);
	}
}

@media only  screen and (min-width : 820px) {
	.rc-slider-rail{
		background: none;
		height: 51px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-41px);
	}
}

@media only  screen and (min-width : 912px) {
	.rc-slider-rail{
		background: none;
		height: 45px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-15px);
	}
}

@media only  screen and (min-width : 1024px) {
	.rc-slider-rail{
		background: none;
		height: 45px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-28px);
	}
}

@media only  screen and (min-width : 1280px) {
	.rc-slider-rail{
		background: none;
		height: 60px;
		background-image: url("../public/sliderBG.svg");
		background-size: 100%;
		background-repeat: no-repeat;
		transform: translateY(-49px);
	}
}
/* select item  */



  /* input time  */

  .btn-hover {
	background: var(--222, linear-gradient(90deg, #2E74BD, #2E74BD ));
	transition: all ease-in-out 0.4s;

  }
 .btn-hover:hover {
  background: var(--222, linear-gradient(90deg, #0A162A , #2E74BD ));

 }
  body, html { 
	font-family: 'cairo', !important ;
  }


  /* datepicker */ 

  .DateInput {
	width: 150px;
    height: 55px;
	cursor: pointer;
  }
  #nc__rf_.DateInput_input .DateInput_input_1 .DateInput_input__focused 
  .DateInput_input__focused_2 , #nc__rf_.DateInput_input .DateInput_input_1 ,
  #nc__r3_ , #nc__rh_  , #nc__ru_.DateInput_input .DateInput_input_1 , #nc__ra_.DateInput_input .DateInput_input_1 ,
  #nc__r1 .DateInput_input .DateInput_input_1 .DateInput_input__focused .DateInput_input__focused_2 
  {
	width: 170px;
    height: 55px;
	cursor: pointer;
  }

  #nc__rf_,
  #nc__rf_,
  #nc__r3_,
  #nc__rh_,
  #nc__ru_,
  #nc__ra_,
  #nc__r1 ,
  #nc__r7 ,
  #nc__rg_{
	width: 170px;
    height: 55px;
	cursor: pointer;
	background-color: #0A162A;
  }

  .DateInput_input .DateInput_input_1 ,#nc__rg_ .DateInput_input .DateInput_input_1 , #nc__r7_.DateInput_input.DateInput_input_1 {
	width: 170px;
    height: 55px;
	cursor: pointer;
  }
 
  @media screen and (max-width: 480px) {
	.DateInput {
		width: 300px;
		height: 55px;
		cursor: pointer;
	  }
	  #nc__rf_.DateInput_input .DateInput_input_1 .DateInput_input__focused 
	  .DateInput_input__focused_2 , #nc__rf_.DateInput_input .DateInput_input_1 ,
	  #nc__r3_ , #nc__rh_  , #nc__ru_.DateInput_input .DateInput_input_1 , #nc__ra_.DateInput_input .DateInput_input_1 ,
	  #nc__r1 .DateInput_input .DateInput_input_1 .DateInput_input__focused .DateInput_input__focused_2 
	  {
		width: 300px;
		height: 55px;
		cursor: pointer;
	  }
	
	  #nc__rf_,
	  #nc__rf_,
	  #nc__r3_,
	  #nc__rh_,
	  #nc__ru_,
	  #nc__ra_,
	  #nc__r1 ,
	  #nc__r7 ,
	  #nc__rg_{
		width: 300px;
		height: 55px;
		cursor: pointer;
		background-color: #0A162A;
	  }
	
	  .DateInput_input .DateInput_input_1 ,#nc__rg_ .DateInput_input .DateInput_input_1 , #nc__r7_.DateInput_input.DateInput_input_1 {
		width: 300px;
		height: 55px;
		cursor: pointer;
	  }
	 
  }

  @media screen and (min-width : 1400px) {
	.over {
	  width: 20%;
	  height: auto;
	  text-align: center;
	  
	}

	.center {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}
	.over .to , .over .from , .over .svg {
	    padding: 10px;
		font-size: 16px;
	}
	svg {
		width: 30px;
		height: 30px;
	}

	.btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 12vw;
		height: 40px;
		margin-right: -80px;
	}
  }@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
[type=checkbox] {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

[type=checkbox] {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
[type=checkbox]::before, [type=checkbox]::after {
  position: absolute;
  display: block;
  content: "";
}
[type=checkbox]::before {
  /* top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border: 5px solid #ddd;
  border-radius: 5px; */
}
@keyframes checkmark-animation {
	0% {
	  opacity: 0;
	  border-left-width: 0;
	  border-bottom-width: 0;
	}
	50% {
	  opacity: 1;
	  border-left-width: 0.125rem;
	  border-bottom-width: 0.025;
	}
	100% {
	  opacity: 1;
	  border-left-width: 0.125rem;
	  border-bottom-width: 0.125rem;
	}
  }
  
[type=checkbox]::after {
  top: 5px;
  left: 4px;
  width: .8rem;
  height: calc(.8rem/2);
  border-bottom: 0.125rem solid #1D4078;
  border-left: 0.125rem solid #1D4078;
  transform: rotate(-45deg);
  /* transition: opacity 0.3s, border-left 0.65s ease-in-out, border-bottom 0.65s ease-in-out; */
  opacity: 0;
}
[type=checkbox]:checked::after {
  opacity: 0.8;
  animation: checkmark-animation 0.65s ease-in-out forwards;
}


* {
	font-family: 'cairo', !important ;
}